import * as React from 'react'
import { graphql } from 'gatsby'
import shortid from 'shortid'
import { Box, Chip, Container, Grid } from '@mui/material'
import Breadcrumbs from '../components/Breadcrumbs'
import RichText from '../components/RichText'
import Content from '../components/Content'
import Seo from '../components/Seo'
import buildOgpTags from '../services/buildOgpTags'
import buildMetaData from '../services/buildMetaData'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useTranslation } from '../contexts/TranslationProvider'
import BuyButton from '../components/buttons/BuyButton'
import addToSchema from '../services/addToSchema'


const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2)
  let numberFormat = new Intl.NumberFormat(["ro-RO"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: 0
  })
  return numberFormat.format(price)
}

const SubscriptionTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const page = data.page || {}

  const stripeProduct = data?.stripeProduct
  const stripePrice = data?.stripePrice

  const isBrowser = () => typeof window !== "undefined"

  const queryString = isBrowser() ? window.location.search : [];
  const urlParams = new URLSearchParams(queryString);
  const transactionSuccess = urlParams.get('transaction-success') === 'true'
  const transactionCancel = urlParams.get('transaction-cancel') === 'true'
  const pathname = isBrowser() ? window.location.pathname : ''

  const getLabels = (labels) => {
    const list = labels
      .filter((l) => l.name && l.color)

    if (stripePrice) {
      list.unshift({
        name: formatPrice(stripePrice.unit_amount, stripePrice.currency),
        color: '#feeae9'
      })
    }

    if (list.length <= 0) {
      return <></>
    }

    return <Grid
      spacing={1}
      container
      alignItems="center"
      justifyContent="center">
      {list.map(label => <Grid item key={`label-chip-${shortid.generate()}`}>
        <Chip
          label={label.name} sx={{
            backgroundColor: label.color,
            fontSize: '12px',
          }} />
      </Grid>)}
    </Grid>
  }

  return <>
    <br />
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs breadcrumbs={pageContext.breadcrumbs} />
        </Grid>

        <Grid item xs={12}>
          <br />
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center">
            <Grid item xs={12} sm={3}>
              <Box sx={{

                background: '#F5F5F5',
                borderRadius: '10px',
                WebkitMaskImage: '-webkit-radial-gradient(white, black);',
                display: 'flex',
                alignItems: 'center',

                justifyContent: 'center'
              }}>
                <Box
                  component={GatsbyImage}
                  image={getImage(page.data.image.gatsbyImageData)}
                  alt={page.data.image.alt ?? 'page header icon'} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={9}>
              <RichText
                sx={{
                  textAlign: 'center'
                }}
                content={page.data.title.richText}
              />
              {getLabels(page.data.labels)}
            </Grid>
          </Grid>
        </Grid>

        {transactionSuccess &&
          <Grid item xs={12}>
            <Box sx={{
              height: 140,
              background: '#FFF5F5',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              padding: 2,
              color: 'secondary.main',
              textAlign: 'center',
              justifyContent: 'center'
            }}>
              {t('paymentSuccess')}
            </Box>
          </Grid>}

        {transactionCancel &&
          <Grid item xs={12}>
            <Box sx={{
              height: 140,
              background: '#FFF5F5',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              padding: 2,
              color: 'secondary.main',
              textAlign: 'center',
              justifyContent: 'center'
            }}>
              {t('paymentCancel')}
            </Box>
          </Grid>}

        {!transactionSuccess &&
          <Grid item xs={12}>
            <BuyButton
              price={stripePrice}
              product={stripeProduct}
              successUrl={`${process.env.GATSBY_BASE_URL}${pathname}?transaction-success=true`}
              cancelUrl={`${process.env.GATSBY_BASE_URL}${pathname}?transaction-cancel=true`}
            >
              {t('buyNow')} {formatPrice(stripePrice.unit_amount, stripePrice.currency)}
            </BuyButton>
          </Grid>}


      </Grid>
    </Container>

    <Content doc={page} />
  </>
}

export const Head = ({ data, pageContext }) => {
  const page = data.page || {}

  const meta = buildMetaData(page)
  const ogp = buildOgpTags(page, pageContext)

  let schema = page.data?.schema_json?.text || ""

  // FAQ
  if (page.data?.faq?.length) {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": page.data.faq.map(({ question: faq }) => (
        {
          "@type": "Question",
          "name": faq.document.data.question.text.replace(/(\r\n|\n|\r)/gm, ""),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.document.data.answer.text.replace(/(\r\n|\n|\r)/gm, "")
          }
        }
      ))
    })
  }

  return <Seo schema={schema} ogp={ogp} meta={meta} breadcrumbs={pageContext.breadcrumbs} path={pageContext.url} />
}

export const query = graphql`
  query subscriptionQuery($id: String, $lang: String, $stripeProductId: String) {
    page: prismicSubscription(id: { eq: $id }, lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      id
      uid
      lang
      url
      type      
      data {           
        meta_title
        meta_description
        meta_keywords
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        faq {
          question {
            document {
              ... on PrismicFaq {
                id
                data {
                  answer {
                    text
                  }
                  question {
                    text
                  }
                }
              }
            }
          }
        }
        title {
          text
          richText
        }
        description {
          richText
        }
        image {
          alt
          dimensions {
            height
            width
          }
          gatsbyImageData
        }
        labels {
          name
          color
        }  



        body {
          ... on PrismicSubscriptionDataBodySimpleText {
            id
            primary {
              text {
                richText
                text
              }
            }
            slice_type
          }


          ... on PrismicSubscriptionDataBodyImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData
                alt
              }
            }
          }


          ... on PrismicSubscriptionDataBodyTextWithIcon {
            id
            slice_type
            primary {
              icon_image {
                gatsbyImageData
                alt
              }
              text {
                richText
                text
              }
            }
          }


          ... on PrismicSubscriptionDataBodyWrappedImage {
            id
            slice_type
            slice_label
            primary {
              wrapped_image_image_size
              wrapped_image_text_size
              wrapped_image_text_background_full
              image_position
              wrapped_image {
                alt
                gatsbyImageData
              }
              wrapped_text {
                richText
                text
              }
            }
          }



          ... on PrismicSubscriptionDataBodyAd {
            id
            slice_type
            items {
              ad_size
              ad {
                document {
                  ... on PrismicAd {
                    id
                    type
                    uid
                    data {
                      title {
                        text
                      }
                      subtitle
                      url
                      left_upper_text {
                        richText
                      }
                      left_lower_text {
                        richText
                      }
                      image {
                        alt
                        gatsbyImageData(height: 255)
                      }
                    }
                  }


                  ... on PrismicBanner {
                    id
                    type
                    uid
                    data {
                      type
                      title {
                        text
                      }
                      subtitle                      
                      button_text
                      url
                      image {
                        alt
                        gatsbyImageData
                      }                      
                    }
                  }
                }
              }
            }
          }





          ... on PrismicSubscriptionDataBodyCarousel {
            id
            slice_type
            items {
              slide_image {
                gatsbyImageData(width: 400)
                alt
              }
            }
            primary {
              content {
                text
                richText
              }
            }
          }



          ... on PrismicSubscriptionDataBodyIntro {
            id
            slice_type
            primary {
              intro_headline {
                richText
              }
              intro_box_1_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              intro_box_2_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              intro_description {
                richText
              }
              intro_box_3_text {
                richText
              }
            }
          }


          ... on PrismicSubscriptionDataBodyAboutUs {
            id
            slice_type
            primary {
              about_us_title {
                richText
              }
              about_us_description {
                richText
              }
              about_us_upper_left_text
              about_us_upper_right_text
              about_us_lower_left_text
              about_us_lower_right_text
            }
          }


        }
      }
    }

    stripeProduct(id: {eq: $stripeProductId}) {
      name
      id
      default_price
    }

    stripePrice(product: {id: {eq: $stripeProductId}}) {
      id
      type
      unit_amount
      unit_amount_decimal
      active
      currency      
    }
  }
`

export default SubscriptionTemplate